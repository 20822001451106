@use "libs/shared/styles/mixins" as *;
@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@import "course-icons";
@import "mixins";

.quill-table {
    width: 100%;
    border: $color-gray-100 solid 1px;
    border-radius: 8px;
    border-spacing: 0;

    &.fixed-layout {
        table-layout: fixed;
    }

    th {
        background-color: $color-gray-600;
        color: $color-white;
        height: 30px;
        border-right: 1px solid $color-white;
        box-sizing: border-box;

        &:last-child {
            border-top-right-radius: 7px;
            border-right: 0;
        }

        &:first-child {
            background-color: $color-gray-400;
            border-top-left-radius: 7px;
        }
    }

    td {
        min-width: 60px;
        background-color: transparent;
        height: 30px;
        border-bottom: 1px solid $color-white;
        border-right: 1px solid $color-white;
        box-sizing: border-box;

        &:first-child {
            background-color: $color-white;
        }

        &:last-child {
            border-right: 0;
        }
    }

    tr {
        background-color: #f1f2f5;
        text-align: center;
        padding: 4px;

        &:nth-child(2n) {
            background-color: $color-gray-50;

            td:first-child {
                background-color: #f8f9fa;
            }
        }

        &:last-child {
            td {
                border-bottom: none;
            }

            td:last-child {
                border-bottom-right-radius: 7px;
            }

            td:first-child {
                border-bottom-left-radius: 7px;
            }
        }
    }
}

.ql-editor,
.elearning-course-card {
    .ql-size-small {
        font-size: $typography-size-body-description;
    }

    .ql-size-large {
        font-size: $typography-size-heading-4;
    }

    .ql-size-huge {
        font-size: $typography-size-heading-1;
    }

    a {
        font-weight: $typography-weight-bold;
        color: $color-secondary-700;
        @include for-viewport-size(smartphone, smartphone-wide) {
            word-break: break-word;
        }
    }

    ul,
    ol {
        padding: 16px;
    }

    ol {
        list-style: decimal outside;
    }

    ul {
        list-style: outside;
    }

    .infobox-blue {
        @include infobox("/assets/images/lightbulb.svg", $color-secondary-100, $color-secondary-700);
    }

    .infobox-red {
        @include infobox("/assets/images/alert.svg", $color-status-error-background, $color-error-400);
    }

    .infobox-yellow {
        @include infobox("/assets/images/book.svg", $color-primary-100, $color-primary-900);
    }

    .infobox-physics {
        @include infobox("/assets/images/physics.svg", $color-secondary-100, $color-secondary-500);
    }

    .definition-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $color-white;
        border: 1px solid $color-gray-100;
        border-radius: 6px;
        padding: 20px;
    }

    .d-flex {
        display: flex;
    }

    .align-items-center {
        align-items: center;
    }

    .justify-content-center {
        justify-content: center;
    }

    .img-desc {
        text-align: center;
        color: $color-gray-300;
    }

    ol > li::marker {
        font-weight: bold;
    }

    @include for-viewport-size(smartphone, smartphone-wide) {
        img {
            max-width: 100% !important;
            height: auto !important;
        }
    }
}

.elearning-course-card {
    @include body-16;
    @import "spacing";
    color: $color-gray-700;
}

.secondary-strong-paragraph {
    font-weight: $typography-weight-bold;
    color: $color-secondary-700;
}
