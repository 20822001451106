$icons: cloud, eye, lack-of-damage, engine-condition, technical-condition, controller, position-light, remote-control, compass-calibration,
    operator-number, equipment-condition, battery-condition, propeller-attachment, video-transmission, loss-of-communication, go-home, sun,
    thermometer, voltage, wind, droplet, top-down-drone, side-view-drone, upside-down-drone;
$icon-size: 24px;

@each $icon in $icons {
    .icon-#{$icon}::before {
        content: url("../assets/course-icons/#{$icon}.svg");
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
    }
}
