$spacer: 1rem;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: (
            $spacer,
        ),
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
    ),
    ()
);

@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length;
        }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length;
        }
    }
}
