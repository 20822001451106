@mixin infobox($icon-url, $border-color, $header-color) {
    @include body-small-short;
    border-radius: 6px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid $border-color;
    display: flex;
    flex-wrap: wrap;
    &::before {
        content: url($icon-url);
        display: flex;
        align-items: center;
        justify-content: center;
        order: -2;
        margin-bottom: 8px;
    }
    &::after {
        content: attr(data-infobox-header);
        color: $header-color;
        font-size: 20px;
        font-weight: 700;
        margin-left: 12px;
        display: flex;
        align-items: center;
        order: -1;
        width: calc(100% - 44px);
        margin-bottom: 16px;
    }
}
